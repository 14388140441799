<template>
    <v-card>
      <v-card-text>
        
      <v-row>
        <!-- COBRANZA -->
        <v-col cols="12" sm="12" md="12" v-for="(element, key) in collectionPolicies" :key="key">
          <v-card color="rgba(0, 0, 0, 0.1)" >
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Cobranza de Póliza N° {{ element.policy_number }}
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Cuota
                      </th>
                      <th class="text-left">
                        Valor
                      </th>
                      <th class="text-left">
                        Valor Pagado
                      </th>
                      <th class="text-left">
                        Saldo
                      </th>
                      <th class="text-left">
                        Fecha de Pago
                      </th>
                      <th class="text-left">
                        Dias de Mora
                      </th>
                      <th class="text-left">
                        Comprobante
                      </th>
                      <th class="text-left">
                        Recibo
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in element.collections"
                      :key="key"
                    >
                      <td>{{ item.number_quote }}</td> 
                      <td>
                        <div v-if="element.type_currency == 1">
                          <span style="color: red;">L</span> {{ numberWithCommas(item.calculated_amount) }}
                        </div>
                        <div v-else>
                          <span style="color: green;">$</span> {{ numberWithCommas(item.calculated_amount) }}
                        </div>
                      </td>
                      <td> <div v-if="item.payment_amount > 0"> {{ numberWithCommas(item.payment_amount) }} </div></td>
                      <td> <div v-if="item.payment_amount > 0">
                        {{ (parseFloat((item.payment_amount).replace(/,/g, "")) - parseFloat(item.calculated_amount)).toFixed(2) }}
                      </div> </td>
                      <td>{{ item.payment_date }}</td>
                      <td>{{ days_overdue(item) }}</td>
                      <td> 
                          <v-icon 
                          v-if="item.path_picture"
                          color="purple darken-3"
                          @click="seePicture(item.path_picture)"
                          >fas fa-image</v-icon>
                      </td>
                      <td>
                          <v-icon   
                          v-if="item.path_bill"
                          color="purple darken-3"
                          @click="seeDocument(item.path_bill)"
                          >fas fa-file-pdf</v-icon>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">Total Pagado: </td>
                      <td><b> {{ numberWithCommas(sumField(element.collections,'payment_amount').toFixed(2) ) }} </b></td>
                      <td>Saldo: </td>
                      <td><b> {{ numberWithCommas( ( sumField(element.collections,'payment_amount') - sumField(element.collections,'calculated_amount') ).toFixed(2) ) }} </b></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

      </v-row>
      </v-card-text>
    </v-card>

</template>

<script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import * as moment from 'moment';

  export default {
    name: "collectivePays",
    data: () => ({
      titulo: "Pagos",
      policies: [],
      // id_customer: 142,
      collections: [],
      data: {},
      coins: [
        { id: 1, name: "Lempiras" },
        { id: 2, name: "Dólares" },
      ],
    }),
    
    methods: {
      ...mapActions(["cerrarSesion", "validateSession"]),

      async getData(){
        console.log(this.id_customer);
        this.policies = [];
        this.collections = [];
        if (!this.id_customer > 0) {
          return;
        }
        try {

            await axios.get(this.db + 'customer/policies/' + this.id_customer,
            {
              headers: this.headers_db
            }).then(response => {
              this.policies = response.data.data;
              console.log('policies', this.policies);
            }).catch((error) => {
              this.validateSession(error).then((resp) => { if(resp) this.getData(); })
            });

        } catch (e) {
          console.log(e);
        }
      },

      async seeDocument(path){
      await axios.get(this.db + 'customer/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getData();
          }
      });
    },

    async seePicture(path){
      await axios.get(this.db + 'customer/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'image/jpeg' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getData();
          }
      });
    },

      dateFormat1(date) {
        return moment(date).format('DD/MM/YYYY hh:mm a');
      },
      dateFormat2(date) {
        return moment(date).format('DD/MM/YYYY');
      },

      sumarTotales(array) {
        
        let totalPagado = 0;
        for (const item of array) {
          if (item.payment_amount > 0) {
            totalPagado += parseFloat(item.payment_amount.replace(/,/g, ""));
          }
        }
        return this.numberWithCommas(totalPagado.toFixed(2));
      },

      sumarSaldo(array) {
        
        let totalPagado = 0;
        for (const item of array) {
          if (item.payment_amount > 0) {
            totalPagado += parseFloat(item.payment_amount.replace(/,/g, ""));
          }
        }
        return this.numberWithCommas(totalPagado.toFixed(2));
      },

      sumField(array,key) {
        return array.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
      },

      days_overdue(item) {
        let payment_amount = (item.payment_amount > 0) ? parseFloat(item.payment_amount) : 0;
        let calculated_amount = (item.calculated_amount > 0) ? parseFloat(item.calculated_amount) : 0;
        let balance = payment_amount - calculated_amount;
        let now = moment();
        
        if (balance < 0) {
          const daysPassed = now.diff(item.payment_date, 'days');
          return daysPassed;
        }
      },

      validityFormat(date1, date2) {
        date1 = moment(date1).format('DD-MM-YYYY');
        date2 = moment(date2).format('DD-MM-YYYY');
        return date1 + ' ~ ' + date2;
      },

      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

    },
    computed: {
      ...mapState(["db","headers_db"]),
      cargando() {
        return this.carga ? "loading" : "false";
      },
      collectionPolicies() {
        return this.policies.filter(policy => policy.collections.length);
      },

    },

    async mounted() {
       await this.getData();
    },

    props: {
      id_customer: String
    }
  }
</script>

<style>
  .small {
    max-height: 350px;
    /* margin:  150px auto; */
  }
</style>