<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
      <v-form ref="form" v-model="valid" autocomplete="off">
        <v-row>
          <!-- <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="name"
              item-value="id"
              label="Escoge un Cliente"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col> -->

          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_insurance"
              :items="insurers"
              item-text="name"
              item-value="id"
              label="Escoge una Aseguradora"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                clearable
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(dates);
                    updateDateFilter();
                  "
                  >Aceptar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        
          
          <v-col cols="12" sm="12" md="2">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="getByCustomer"
              block
            >
              Filtrar <v-icon right >
                fas fa-filter
              </v-icon>
            </v-btn>
          </v-col>

        </v-row>


      </v-form>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      single-expand
      show-expand
      @item-expanded="loadDetails"
    >

    <template v-slot:[`item.picture`]="{ item }">
      <v-icon 
      v-if="item.path_picture"
      color="purple darken-3"
      @click="seePicture(item.path_picture)"
      >fas fa-image</v-icon>
    </template>


    <template v-slot:[`item.bill`]="{ item }">
      <v-icon 
      v-if="item.path_bill"
      color="purple darken-3"
      @click="seeDocument(item.path_bill)"
      >fas fa-file-pdf</v-icon>
    </template>

    
    <!-- OPCIONES EXPANDIBLES -->
     <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length"  class="pa-4">

        <CollectivePay :key="item.id" :id_customer="item.id"></CollectivePay>
        
      </td>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import CollectivePay from '@/components/CollectivePay.vue';

export default {
  components: { CollectivePay },
  name: "reportPolicies",
  data: () => ({
    dialog: false,
    currentItems: [],
    policies: [],
    insurers: [],
    customers: [],
    type: "",
    id_customer: null,
    id_insurance: null,
    dates: [],
    active: false,
    modal: false,
    loader: null,
    loading3: false,
    search: "",
    loading: false,
    titulo: "Reporte de Pagos",
    headers: [
      { text: "Fecha", align: "left", width:"", value: "birthday"},
      { text: "RTN", align: "left", value: "rtn" },
      { text: "Nombres", align: "left", value: "name" },
      { text: "Apellidos", align: "left", value: "surname" },
      { text: "Teléfono", align: "left", value: "phone" },
    ],
    ramos: [
      { id: 1, name: "VIDAS" },
      { id: 2, name: "DAÑOS" },
      { id: 3, name: "FIANZAS" },
    ],
    coins: [
      { id: 1, name: "Lempiras" },
      { id: 2, name: "Dólares" },
    ],
    valid: true
  }),

  computed: {
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  watch: {
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    async getPolicies(){
      await axios.get(this.db + 'customers',
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = this.currentItems = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
    },
    
    // async getCustomers(){
    //   await axios.get(this.db + 'list/customers',
    //     {
    //       headers: this.headers_db
    //     }).then(response => {
    //       this.customers = response.data.data;
    //       this.loading = false;
    //       console.log(response.data.data);
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
    //     });
    // },

    async getInsurers(){
      await axios.get(this.db + 'insurers',
        {
          headers: this.headers_db
        }).then(response => {
          this.insurers = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getInsurers(); })
        });
    },

    async loadDetails(item) {
      console.log(item);
      if (item.value) {
        let element = item.item;
        try {
          if (element.id_ramo > 0) {
            element.ramo = this.ramos.filter(doc => {
              return doc.id == element.id_ramo
            })[0].name;
          }
        } catch {}
      }

    },

    // async seeDocument(path){
    //   await axios.get(this.db + 'customer/download/' + path,
    //   {
    //     responseType: 'arraybuffer',
    //     headers: {
    //         // 'Content-Type': 'multipart/form-data',
    //         "Content-type": "application/x-www-form-urlencoded",
    //         'Authorization': 'BEARER ' + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //       console.log(response );
    //       let blob = new Blob([response.data], { type: 'application/pdf' } ),
    //       url = window.URL.createObjectURL(blob)

    //       window.open(url);
    //   })
    //   .catch(error => {
    //       console.log( error );
    //       if (error.response.status == 401) {
    //         this.getCustomers();
    //       }
    //   });
    // },

    // async seePicture(path){
    //   await axios.get(this.db + 'customer/download/' + path,
    //   {
    //     responseType: 'arraybuffer',
    //     headers: {
    //         // 'Content-Type': 'multipart/form-data',
    //         "Content-type": "application/x-www-form-urlencoded",
    //         'Authorization': 'BEARER ' + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //       console.log(response );
    //       let blob = new Blob([response.data], { type: 'image/jpeg' } ),
    //       url = window.URL.createObjectURL(blob)

    //       window.open(url);
    //   })
    //   .catch(error => {
    //       console.log( error );
    //       if (error.response.status == 401) {
    //         this.getCustomers();
    //       }
    //   });
    // },

    validityFormat(date1, date2) {
      date1 = moment(date1).format('DD/MM/YYYY');
      date2 = moment(date2).format('YYYY');
      return date1 + ' ~ ' + date2;
    },
    dateFormat2(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.dates.length > 1) {
        if (moment(this.dates[0], "YYYY-MM-DD") > new Date(this.dates[1])) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      } else {
        this.dates = [this.dates[0], this.dates[0]];
      }
    },

    getByCustomer() {
      console.log('dates', this.dates);

      if (this.id_customer && this.dates[0] && this.dates[1] && this.id_insurance) {
        this.currentItems = this.banks.filter(doc => {
          let date_db = moment(doc.validity_date1).format('YYYY-MM-DD');
          return doc.id_customer == this.id_customer && doc.id_insurance == this.id_insurance && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_customer && this.dates[0] && this.dates[1]) {
        this.currentItems = this.banks.filter(doc => {
          let date_db = moment(doc.validity_date1).format('YYYY-MM-DD');
          return doc.id_customer == this.id_customer && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_insurance && this.dates[0] && this.dates[1]) {
        this.currentItems = this.banks.filter(doc => {
          let date_db = moment(doc.validity_date1).format('YYYY-MM-DD');
          return doc.id_insurance == this.id_insurance && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }

      else if (this.id_insurance && this.id_customer) {
        this.currentItems = this.banks.filter(doc => {
          return doc.id_insurance == this.id_insurance && doc.id_customer == this.id_customer;
        });
      }
      
      else if (this.id_customer) {
        this.currentItems = this.banks.filter(doc => {
          return doc.id_customer == this.id_customer;
        });
      }

      else if (this.id_insurance) {
        this.currentItems = this.banks.filter(doc => {
          return doc.id_insurance == this.id_insurance;
        });
      }

      else if (this.dates[0] && this.dates[1]) {
        this.currentItems = this.banks.filter(doc => {
          let date_db = moment(doc.validity_date1).format('YYYY-MM-DD');
          return date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      else {
        this.currentItems = this.banks;
      }
    },

    async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      // await this.getCustomers();
      await this.getInsurers();
      this.getPolicies();
    },

  },
  async mounted() {
    this.loading = true;
    // await this.getCustomers();
    await this.getInsurers();
    this.getPolicies();
  }
};
</script>

